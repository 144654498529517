<template>
  <div>
    <h1 class="headline">Explore nutrients</h1>
    <p>For over 200 nutrients we list the most efficient sources to get your daily healthy dose. We'll also show some popular recipes with high intake of each nutrient.</p>
    <v-row>
      <v-col cols="12" sm="12" md="12">
          <v-text-field
            prepend-icon="fa-search" 
            label="Search nutrient by name"
            solo
            v-model="query" 
            @keyup.enter="searchNutrients"
          ></v-text-field>
        </v-col>
    </v-row>

    <v-alert type="info" v-if="resultCount == 0">
      No results found matching your query. Try searching by a different name.
    </v-alert>
    <v-alert type="success" v-if="resultCount > 0">
      {{resultCount}} nutrients have been found matching your criteria.
    </v-alert>

    <NutrientGrid :items="results" />

    <div v-if="!resultCount && popularNutrients">
      <h3 class="headline">Popular nutrients</h3>
      <p>Explore the most important nutrients for vegans.</p>
      <NutrientGrid :items="popularNutrients" />
    </div>

    <div v-if="categories && categories.length">
      <h3 class="headline mt-4">Browse by category</h3>
      <p>Explore all nutrients by their category.</p>
      <GenericCardGrid :items="categories" v-slot="slotProps">
        <v-card color="secondary" dark :to="{name: 'nutrientCategory', params: {id: slotProps.item.id}}">
          <v-card-title>
            {{slotProps.item.name}}
          </v-card-title>
        </v-card>
      </GenericCardGrid>
    </div>

    <ArticleGrid :items="articles" title="Articles on nutrients" class="mt-4" />
  </div>
</template>

<script>
import NutrientGrid from "@/components/NutrientGrid.vue";
import ArticleGrid from "@/components/ArticleGrid.vue";
import GenericCardGrid from "@/components/GenericCardGrid.vue";
import nutrientService from "@/services/nutrientService";
import articleService from "@/services/articleService";
import axios from "axios";

export default {
  name: "Nutrients",
  components: {
    NutrientGrid,
    ArticleGrid,
    GenericCardGrid,
  },
  props: {
  },
  data() {
    return {
      results: [],
      resultCount: null,
      popularNutrients: null,
      categories: null,
      query: null,
      articles: null,
    };
  },
  async mounted() {
    var response = await axios.get("/static/homepage.json");
    this.popularNutrients = response.data.nutrients;
    this.articles = await articleService.listByCategory('nutrients');

    var result = await nutrientService.getCategories();
    this.categories = result.data.data;
  },
  methods: {
    searchNutrients() {
      console.log("search", this.query);
      nutrientService.search(this.query).then(response => {
        this.results = response.data.data;
        this.resultCount = response.data.meta.count;
      }).catch(() => {
        this.results = [];
        this.resultCount = null;
      });
    },
  },
  watch: {
  }
};
</script>
